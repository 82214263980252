.custom-dropdown {
    position: relative;
    display: inline-block;
    width: 20%; /* Adjust as needed */
}
 
.selected {
    border: 1px solid gray; /* Adjust border color */
    border-radius: 5px 0px 0px 5px; /* Adjust border radius */
    display: flex;
    align-items: center;
    background: white; /* Adjust background color */
    cursor: pointer;
}
 
.options {
    position: absolute;
    z-index: 1000;
    border: 1px solid #ccc; /* Adjust border color */
    border-radius: 4px; /* Adjust border radius */
    background: white; /* Adjust background color */
    width: 495%; /* Match the width of the dropdown */
    max-height: 200px; /* Adjust max height */
    overflow-y: auto; /* Add scroll if needed */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
}
 
.option {
    display: flex;
    align-items: center;
    padding: 10px; /* Adjust padding */
    cursor: pointer;
}
 
.option:hover {
    background-color: #f0f0f0; /* Change background on hover */
}
 
img {
    margin-right: 8px; /* Space between image and text */
}